import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { client } from "api/client";
import { useTranslation } from "react-i18next";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { trackEvent } from "helpers/analyticsHelpers";
import { useForm, Errors, PasswordValidation } from "hooks/useForm";
import {
  getToken,
  getDefaultUserOrg,
  getUserOrgs,
  getUserInfo,
} from "store/user/thunks";
import { identify } from "store/user/slice";
import mean from "lodash/mean";
import rollbar from "helpers/rollbar";
import ResizeObserver from "resize-observer-polyfill";
// uncomment if we want to add styles to this wrapper component.
// import styles from "components/forms/AccountCreationPage.module.css";
import { AppAccountCreationFormWrapper } from "components/forms/AppAccountCreationFormWrapper";
import { WebAccountCreationFormWrapper } from "components/forms/WebAccountCreationFormWrapper";
import { useGetReferrer } from "hooks/useGetReferrer";
import { AppDispatch } from "../../store/store";
import { tryGetProp } from "helpers/utils";
import { useGetHostClient } from "hooks/useGetHostClient";
import { useHandleCoupon } from "hooks/useHandleCoupon";
import { ParentFrame } from "clients/types";
import { connectToParent } from "penpal";

const AccountCreationForm: React.FC<any> = ({ props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t, i18n } = useTranslation();
  const { loadingReferrer, referrer } = useGetReferrer();
  const lang = tryGetProp(props, "lang");
  const showLoginBtn = tryGetProp(props, "showLoginBtn", false);
  const subscribe = tryGetProp(props, "subscribe", "true") !== "false";
  const [tag] = useState(tryGetProp(props, "tag"));
  const [urlParams] = useState<URLSearchParams>(new URLSearchParams(window.location.search));
  const { hostClient } = useGetHostClient();

  const { handleCouponCode, removeCoupon, resellerInventoryItem } = useHandleCoupon(hostClient.clientId, tryGetProp(props, "couponCode"));

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    const connection = connectToParent<ParentFrame>({
      parentOrigin: process.env.REACT_APP_WEB_HOST_URI,
    });
    const resizeObserver = new ResizeObserver(entries =>  {
      connection.promise.then(parent => parent.resize(entries[0].target.clientHeight));
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
      connection.destroy();
    }
  }, []);


  const onSubmit = useCallback(
    async ({ email, password, confirmPassword, firstName, lastName, industry, industryCustom, ecommercePlatform, ecommercePlatformCustom }) => {
      const body = {
        grant_type: "password",
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        Password: password,
        ConfirmPassword: confirmPassword,
        client_id: hostClient.clientId,
        ResellerInventoryItemId: resellerInventoryItem?.Id,
        Subscribe: subscribe,
        Tag: tag,
        CampaignId: urlParams.get('campaignId'),
        ReferrerId: urlParams.get('referrerId'),
        Industry: industry,
        IndustryCustom: industryCustom
      };
      try {

        await client.account_Register(body);

        await dispatch(getToken({ username: email, password: password }));
        await dispatch(getUserInfo());
        await dispatch(getUserOrgs());
        await dispatch(getDefaultUserOrg());
        dispatch(
          identify({
            Industry: industry,
            "Industry Detail Custom":
              industry === "Other" ? industryCustom : "",
            "Ecommerce Platform": 
              industry === "Retail & Ecommerce" ? ecommercePlatform : "",
            "Ecommerce Platform Custom": 
              ecommercePlatform === "Other, please specify" ? ecommercePlatformCustom : "",
          })
        );

        trackEvent("Email confirmation survey completed", {
          primaryTag: industry,
          customResponse: industryCustom
        });

        try {
          const plans = await client.metrics_GetPlansLtv();
          const meanLtv = mean(
            plans.filter((p) => p.Ltv > 0).map((p) => p.Ltv)
          ).toFixed(2);
          trackEvent("Created Account", {
            source: hostClient.clientId,
            currency: "USD",
            predicted_ltv: meanLtv,
            value: meanLtv,
          });
        } catch (e) {
          // failed to track event
        }

        const authCode = await client.account_GetAuthorizationCode(hostClient.clientId);
        const returnScheme = tryGetProp(props, "returnScheme", null);

        hostClient.onFormSubmit({ authCode, returnScheme });
      } catch (e) {
        // failed registration send error notifs to user
        if (Array.isArray(e.message)) rollbar.error(e.message[0]);
        else rollbar.error(e.message);

        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: "errors:create-account-error",
          })
        );
      }
    },
    [
      dispatch,
      resellerInventoryItem,
      tag,
      urlParams,
      hostClient,
      props,
      subscribe,
    ]
  );

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    passwordValidMap,
  } = useForm<{
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    industry: string;
    industryCustom: string;
    ecommercePlatform: string;
    ecommercePlatformCustom: string;
  }>(
    {
      email: tryGetProp(props, "username", ""),
      password: tryGetProp(props, "password", ""),
      confirmPassword: tryGetProp(props, "confirmPassword", ""),
      firstName: tryGetProp(props, "firstName", ""),
      lastName: tryGetProp(props, "lastName", ""),
      industry: tryGetProp(props, "industry", ""),
      industryCustom: tryGetProp(props, "industryCustom", ""),
      ecommercePlatform: tryGetProp(props, "ecommercePlatform", ""),
      ecommercePlatformCustom: tryGetProp(props, "ecommercePlatformCustom", ""),
    },
    onSubmit,
    (values, pvm?: PasswordValidation) => {
      let errors: Errors = {};
      if (!values.email) {
        errors.email = t("forgot-password:enter-email");
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = t("errors:invalid-email");
      }

      if (!values.password) {
        errors.password = t("errors:password-required-error");
      } else if (!pvm.hasMinLength) {
        errors.password = t("errors:password-length-error");
      } else if (!pvm.hasLower) {
        errors.password = t("errors:password-lower-error");
      } else if (!pvm.hasUpper) {
        errors.password = t("errors:password-upper-error");
      } else if (!pvm.hasDigit) {
        errors.password = t("errors:password-digit-error");
      } else if (!pvm.hasSpecialChar) {
        errors.password = t("errors:password-special-char-error");
      }
      //(!values.password.match(/^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*()_+-=]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i)) {

      if (!values.confirmPassword) {
        errors.confirmPassword = t("errors:password-required-error");
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = t("errors:password-match-error");
      }

      if (!hostClient.minimizeFormContent && !values.industry) {
        errors.industry = t("errors:industry-required");
      }

      if (!hostClient.minimizeFormContent && (!values.industry || (values.industry === "Other" && !values.industryCustom))) {
        errors.industryCustom = t("errors:custom-industry-required");
      }

      if (values.industry === "Retail & Ecommerce" && !values.ecommercePlatform) {
        errors.ecommercePlatform = t("errors:ecommerce-required");
      }

      if ((values.ecommercePlatform === "Other, please specify" && !values.ecommercePlatformCustom)) {
        errors.ecommercePlatformCustom = t("errors:ecommerce-required");
      }

      return errors;
    }
  );

  return (
    <>
      {hostClient.clientId === 'Switcher' ? (
        <AppAccountCreationFormWrapper
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleCouponCode={handleCouponCode}
          removeCoupon={removeCoupon}
          isSubmitting={isSubmitting}
          showLoginBtn={showLoginBtn === "true"}
          resellerInventoryItem={resellerInventoryItem}
          values={values}
          errors={errors}
          passwordValidMap={passwordValidMap}
        />
      ) : (
        !loadingReferrer && <WebAccountCreationFormWrapper
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleCouponCode={handleCouponCode}
          removeCoupon={removeCoupon}
          isSubmitting={isSubmitting}
          showLoginBtn={showLoginBtn === "true"}
          resellerInventoryItem={resellerInventoryItem}
          referrer={referrer}
          values={values}
          errors={errors}
          passwordValidMap={passwordValidMap}
        />
      )}
    </>
  );
};

export default AccountCreationForm;
